.App {
  font-family: 'Lemonada', cursive;
  font-family: 'Almarai', sans-serif;
  /* min-height: 1000vh; */
  /* background-color: #eee; */
  /* z-index: 0; */
  /* position: relative; */
  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --backgroundMain-color: var(--color-Main-background);
  --color-Main-red: #f7434e;
  --color-Main-blue:#233a86;
  --color-Main-dark:#080c14;
  --color-secondry-dark:#111827;
  --color-Main-background: #e6e6e6;
  --main-transition: 0.3s;
}

a {
  text-decoration: none;
}

/* Circle style */
.status-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  margin:0 10px;
  border: 2px solid #777;
  position: relative;
  z-index: 99999999;
  background-color: #e6e6e6;
}

.line-status {
  position: relative;
}

.line-status::after {
  position: absolute;
  content: "";
  width: 3px;
  min-height: 100vh;
  color: red;
  background-color: #777;
  top: 50%;
  transform: translateY(-50%);
  right: 50%;
}




/* .status-circle::before {
  position: absolute;
  content: "";
  width: 3px;
  color: red;
  height: 50px;
} */


/* Green color for opened = 1 */
.status-circle.green {
  position: relative;
  background-color: green;
  border: 2px solid green;

}

.checkmark-icon::before {
  content: "\2713"; /* Unicode for a checkmark symbol */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px; /* Adjust the font size as needed */
  color: white; /* Color of the checkmark */
}



/* Red color for opened = 0 */
.status-circle.red {
  background-color: #e02a24;
  border: 2px solid #e02a24;

}
/* Connecting line style */




.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  padding: 20px 100px;
  z-index: 9999999;
}

/* .nav-bar::after {
  content: "";
  position: fixed;
  background-image: url(../public/images/tl.png);
  top: 100px;
  left: 0;
  width: 313px;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
} */

/* .nav-bar::before {
  content: "";
  position: fixed;
  background-image: url(../public/images/tl.png);
  top: 100px;
  right: 0;
  width: 313px;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotateY(180deg);
} */





@media (max-width: 768px) {
  .nav-bar {
    padding: 15px 0;
    }
    /* .nav-bar::after {
      height: 135px;
      z-index: -1;
      top: 108px;
      display: none;
  }

  .nav-bar::before {
   display: none;
} */

  .nav-bar .login{
     margin-bottom: 10px;
  }
}

.nav-bar.dark-mode.sticky {
  background-color: var(--color-Main-dark);
}

.nav-bar.sticky {
  background-color: #fff;
}

/* .nav-bar.dark-mode {
  background-color: #080c14;
} */

.nav-bar.sticky ul li .login .first-span
 {
  color: #000;
}

.nav-bar.sticky ul li .login .first-span.dark-mode {
  color: #fff;
}
.nav-bar ul li .login .first-span.dark-mode {
  color: #fff;
}

.nav-bar  .logo {
  display: flex;
  align-items: center;
}

.nav-bar  .logo h3{
  color: #000;
  -webkit-text-stroke-width: 0.5px;
  font-size: 2rem;
  letter-spacing: 0px;
  margin-left: 10px;
  transition: var(--main-transition);
  font-weight: 600;
}

.nav-bar.dark-mode  .logo h3{
  color: #fff;
}

.nav-bar  .logo h3 span{
  color: #06b6d4;
  transition: 0.3;

}

.nav-bar.sticky .logo {
  color: #06b6d4;
}

.nav-bar .logo img {
  width: 200px;
}



@media (max-width:767px) {
  .nav-bar .logo img {
    width: 150px !important;
  }
}

.nav-bar.sticky ul li .register {
  color: #fff;
}

.nav-bar.sticky ul li .register:hover {
  color: #06b6d4;
}

.nav-bar .logo {
  position: relative;
    font-size: 1.9rem;
    font-weight: bold;
    text-decoration: none;
    color: #06b6d4;
    transition: var(--main-transition);
}



@media (max-width:767px) {
  
  .nav-bar  .logo h3{
    color: #000;
    -webkit-text-stroke-width: 1px;
    font-size: 1.3rem;
    letter-spacing: 1px;
    margin-left: 0;
    font-weight: 600;
}
}

.nav-bar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-bar .logo:hover {
  color: #06b6d4;
}

.nav-bar ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
}

.nav-bar ul li {
  position: relative;
  list-style: none;
}

.nav-bar ul a {
  position: relative;
  margin: 0 15px;
  text-decoration: none;
  letter-spacing: 0.5px;
}

.menu-toggle {
  display: none;
}



@media (max-width: 768px) {
  .nav-bar.dark-mode .menu-toggle .bar {
    background-color: #fff;
  }
  .nav-bar.dark-mode .menu-toggle img {
    background:none;
  }
  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    right: 10px;
  }

  .menu-toggle .bar {
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: var(--main-transition);
  }

  .menu-toggle.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-8px, 8px);
  }

  .menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-4px, -5px);
  }

  .nav-bar ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--main-transition) ease-in-out;
    background-color: #fff;
    box-shadow: -5px 5px  20px #0006 ;
    border-radius: 0 0 30px 30px;;
  }

  .nav-bar.dark-mode ul {
    background-color: var(--color-secondry-dark);
  }

  .nav-bar ul.show {
    max-height: 500px;
  }

  .nav-bar ul li:first-child {
    margin: 30px 0;
  }

  .nav-bar ul li:last-child {
    margin: 30px 0;
  }

  /* .nav-bar ul .register {
    color: #000;
  } */
  .nav-bar ul .login .first-span {
    color: #000;
  }

}


@media (min-width:767px) and (max-width:1023px) {
  .nav-bar.dark-mode .menu-toggle .bar {
    background-color: #fff;
  }
  .nav-bar.dark-mode .menu-toggle img {
    background:none;
  }
  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    right: 10px;
  }

  .menu-toggle .bar {
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: var(--main-transition);
  }

  .menu-toggle.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-8px, 8px);
  }

  .menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-4px, -5px);
  }

  .nav-bar ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--main-transition) ease-in-out;
    background-color: #fff;
    box-shadow: -5px 5px  20px #0006 ;
    border-radius: 0 0 30px 30px;;
  }

  .nav-bar.dark-mode ul {
    background-color: var(--color-secondry-dark);
  }

  .nav-bar ul.show {
    max-height: 500px;
  }

  .nav-bar ul li:first-child {
    margin: 30px 0;
  }

  .nav-bar ul li:last-child {
    margin: 30px 0;
  }

  /* .nav-bar ul .register {
    color: #000;
  } */
  .nav-bar ul .login .first-span {
    color: #000;
  }
} 




.scroller {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px;
  background-color: #dbdbdb;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}

.scroller.sticky {
  visibility: visible;
  opacity: 1;
}

.scrollerStyle {
  height: 100%;
  background-color: #ff5252; /* Change this to the color you prefer */
  width: 0;
  transition: width var(--main-transition) ease-in-out;
}

.back-link {
  display: flex;
  align-items: center;
  text-align: center;
}

.back-text {
  margin-right: 1rem; /* إضافة مسافة بين الأيقونة والنص على الأجهزة الكبيرة */
}

/* وسائط الاستجابة للأجهزة المحمولة */
@media (max-width: 767px) {
  .back-link {
    flex-direction: column;
  }

  .back-text {
    margin-right: 0; /* إزالة المسافة على الأجهزة الصغيرة */
    margin-top: 0.5rem; /* إضافة مسافة بين النص والأيقونة على الأجهزة الصغيرة */
  }
}




.nav-bar .register {
  background-color: var(--color-Main-red);
  border: 2px  solid var(--color-Main-red);
  color: #fff;
  transition: var(--main-transition);
  padding: 18px 30px;
  -webkit-text-stroke-width: 0.3px;
  border-radius: 7px;
  text-decoration: none;
  font-size: 14px;

}

.nav-bar .register:hover {
  background:none;
  color: var(--color-Main-red);
}

.nav-bar .login {
  transition: var(--main-transition);
padding: 21px 30px;
  -webkit-text-stroke-width: 0.3px;
  border-radius: 7px;
  text-decoration: none;
  font-size: 14px;
}

.nav-bar .login .first-span {
  color: #000;
}

.nav-bar .login .child-span {
  color: #ffbf00;
}


.nav-bar .login:hover {
  box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.4);
  transform: scale(1.1);
}

.nav-bar .register img,
.nav-bar .login img {
  width: 30px;
}


.dark-mode-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lamp-icon {
  color: #999;
  font-size: 24px;
  transition: color var(--main-transition);
  font-size: 45px;

}

.dark-mode-toggle.active .lamp-icon {
  color: #ffd700; /* Yellow color for the active (dark mode) lamp icon */
}

.dark-mode-toggle svg {
  width: 35px ;
}

.mobile-layout {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .mobile-layout {
    flex-direction: row;
    width: 65%;
    justify-content: space-between; /* أو أي تخطيط آخر المطلوب في الجوال */
  }
}

.nav-bar img.user_img {
  width: 60px;
  position: relative;
  cursor: pointer;
}




@media (max-width:767px) {
  .nav-bar img.user_img {
    /* width: 40px;
    position: fixed;
    top: 20px;
    right: 5px; */
    width: 45px;
    }
}

@media (min-width:767px) and (max-width:1023px) { 
  .nav-bar img.user_img {
    width: 60px;
    position: fixed;
    top: 20px;
    right: 20px;
  }
}


.nav-bar .bell-img {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* width: 120px; */
}

.nav-bar .bell-img .bell {
    font-size: 2rem;
    position: relative;
    cursor: pointer;
    margin-right: 15px;

}

@media (max-width:767px) {
  .nav-bar .bell-img  {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 90px;
    position: fixed;
    top: 20px;
    right: 0;
    /* position: fixed;
    top: 15px;
    right: 50px; */
}
}
.div-bell {
  position: relative;
}
.notification-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--color-Main-red);
  color: white;
  border-radius: 50%;
  /* padding: 4px; */
  font-size: 16px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}



.drop {
  background-color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 80px;
  z-index: 9999999;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 10px 0;
  right: 0;
  text-align: right !important;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: opacity var(--main-transition) ease-in-out, visibility var(--main-transition) ease-in-out, transform var(--main-transition) ease-in-out;
}

.drop.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* @media (max-width:768px) {
  .drop {
    right: 0;
    top: 95px;
  }
}
@media (min-width: 1280px) {
  .drop {
    right: 300px;
    top: 105px;
  }
} */
/* @media (min-width: 992px) {
  .drop {
    right: 100px;
    top: 105px;
  }
} */

@media (min-width:767px) and (max-width:1023px) {
  .drop {
    position: fixed;
    top: 105px;
  }
} 

@media (max-width:767px) {
  .drop {
   position: fixed;
   top: 95px;
  }
}


.drop  a {
  color: #000;
  transition: var(--main-transition);
  width: 100%;
  margin: 0;
  padding: 10px 20px 10px 80px;
  display: flex;
  align-items: center;
  justify-content: end;
  transition: transform 0.3s ease-in-out; /* تأثير الانتقال */

}

@media (max-width:767px) {
  .drop  a {
    width: 100%;
  }

}

.drop  a:hover {
  color: #000;
  background-color: var(--color-Main-background);
}


.nav-bar .drop img {
  width: 20px;
  margin-left: 10px;
  
}

.drop a.no-noti {
  width: 350px;
} 

.drop a.no-noti .spinners {
  color: var(--color-Main-red);
  font-size: 1rem;
  font-weight: bold;
}

.drop .back-arrow {
  color: var(--color-Main-red);
  transition: transform 0.3s ease-in-out; /* Add transition for the arrow */


}

.drop a:hover .back-arrow{
  transform: translateX(-5px); /* تحريك لليسار عند الهوفر */
}

@media (max-width:767px) {
  .drop a.no-noti {
    width: 100%;
  } 
}
.drop a.no-noti span{
 color: var(--color-Main-red);
 font-weight: 600;
} 





.nav-bar.dark-mode .drop {
  background-color: var(--color-secondry-dark);
  color: #fff;
}
.nav-bar.dark-mode .drop a{

  color: #fff;
}

.nav-bar.dark-mode .drop a:hover {
  background-color: #1f2937;
}


.footer {
  background-image: url(/public/images/Group8.ef26b606f1dd584974a8.png);
  position: relative;
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  text-align: center;
  background-size: cover;
  transition: var(--main-transition);
}

.footer.dark-mode {
  background-color: var(--color-Main-dark);
  /* color: #fff; */
  transition: var(--main-transition);
}

.footer.dark-mode .develop a,
.footer.dark-mode  h1 {
  color: #fff;
}




.wrapper .button{
  display: inline-block;
  height: 50px;
  width: 50px;
  float: left;
  margin: 0 5px;
  overflow: hidden;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
  transition: all var(--main-transition) ease-out;
}
.wrapper .button:hover{
  width: 200px;
}
.wrapper .button .icon{
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 50px;
  transition: all var(--main-transition) ease-out;
}
.wrapper .button:nth-child(1):hover .icon{
  background: #4267B2;
}
/* .wrapper .button:nth-child(2):hover .icon{
  background: #1DA1F2;
}
.wrapper .button:nth-child(3):hover .icon{
  background: #E1306C;
} */
.wrapper .button:nth-child(2):hover .icon{
  background: #ff0000;
}
.wrapper .button:nth-child(3):hover .icon{
  background: #af3aed;
}
.wrapper .button .icon svg{
  font-size: 25px;
  line-height: 50px;
  transition: all var(--main-transition) ease-out;
  margin-top: 13px
}
.wrapper .button:hover .icon svg{
  color: #fff;
}
.wrapper .button span{
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  margin-left: 25px;
  transition: all var(--main-transition) ease-out;
  text-decoration: none;
}
.wrapper .button:nth-child(1) span{
  color: #4267B2;
  text-decoration: none;
}
/* .wrapper .button:nth-child(2) span{
  color: #1DA1F2;
}
.wrapper .button:nth-child(3) span{
  color: #E1306C;
} */
.wrapper .button:nth-child(2) span{
  color: #ff0000;
}
.wrapper .button:nth-child(3) span{
  color: #af3aed;
}


@media (max-width:767px) {
  .wrapper .button span{
    margin-left: 10px;
  }
}
.footer h1 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 35px;
}

.footer .develop span {
    color: #e02a24;
    font-size: 1.3rem;
    font-weight: 600;
}

.footer .develop a {
  margin: 0 5px;
  font-size: 1.3rem;
  text-decoration: none;
  color: black;
  font-weight: 800;
  transition: var(--main-transition);
}

.footer .develop a:nth-child(3):hover {
  color: #777;
  padding-right: 10px;
}

.footer .develop a:nth-child(2):hover {
  color: #777;
  padding-left: 10px;
}

.wrapper a {
text-decoration: none;
}

@media (max-width:767px) {
  .footer h1 {
    font-size: 1.3rem;
  }
}






.quiz {
  background-color: var(--color-Main-background);
  min-height: 80vh;
  padding-top: 50px;
}


.quiz-page h5{
  text-align: center;
}

.quiz-page .option-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}

.quiz-page .option-container .option {
  background-color: green;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
}

.quiz-page  .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-page  .button-container button{
  border: none;
  color: #fff;
  margin:  30px 100px 0 100px;
  padding: 10px 30px;
}

.quiz-page  .button-container button:first-child {
  background-color: black;
}

.quiz-page  .button-container button:last-child {
  background-color: red;
}

.quiz-page  .button-container button:nth-child(2) {
  background-color: blue;
}

.quiz-page .life-line-container {
  display: flex;
  justify-content:center;
  align-items:center;
}

.quiz-page .life-line-container span {
  margin: 0 200px;
  background-color: #fff;
  padding: 10px 30px;
}

.disable {
    pointer-events: none ;
    background-color: #ccc !important;
    box-shadow: none !important;
    opacity: 0.7 !important;
}
  

 .error {
  color: red;
  font-weight: 500;
  font-size:16px;
}

.no-courses {
  /* background-color: #f9f9f9; */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.no-courses  h2 {
  text-shadow: 0px 0px 40px #e02a24;
  color: #d53752;
font-size: 55px;
font-weight: 700;
}

@media (max-width:767px) {
  .no-courses {
    min-height: 40vh;
  }
  .no-courses  h2 {
font-size: 30px !important;
font-weight: 700;
  }

}




    .youtube-share-button {
    display: none;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #e9e9e9;
  text-align: center;
}

.not-found span {
  color: #e02a24;
  font-weight: 600;
  text-align: center;

}
.number-phone {
  display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    background-color: #e9e9e9;
    flex-direction: column;
    width: 100%;
}

.number-phone h2 {
  color: #000;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 1px;
}


.scroll-top-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f43f5e;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  z-index: 99999999;
}

.messenger img,
.telegram img{
  position: fixed;
    bottom: -15px;
    left: -20px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    /* background-color: #f43f5e; */
    color: #fff;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    transition: opacity 0.4s, visibility 0.4s;
    z-index: 99999999;
    transform: rotateX(45deg);
}

.messenger img {
  bottom: -15px;
  left: -20px;
}
.telegram img {
  bottom: -15px;
  right: -20px;
}
.messenger::before{

  content: "الذهاب الي واتساب الدعم الفني";
  position: fixed;
  background-color: white;
  color: #555;
  box-shadow: 5px 5px 20px #666;
  bottom: 105px;
  left: 20px;
  padding: 8px 10px;
  /* width: 40px; */
  text-align: center;
  border-radius: 4px;
  z-index: 99999999;
}
.messenger::after{
  
  content: "";
  position: fixed;
  border-style: solid;
  border-width: 8px;
  border-color: white transparent transparent;
  /* box-shadow: 5px 5px 20px #666; */
  left: 40px;
  bottom: 90px;
  z-index: 999999999;
}




.scroll-top-button.is-visible {
  opacity: 1;
  visibility: visible;
}



@keyframes animate {
    0% {
      transform: translate(-50% , -75%) rotate(0deg)
    }
    100% {
      transform: translate(-50% , -75%) rotate(360deg)
    }
}


@-webkit-keyframes animate { /* Safari 4.0 - 8.0 */
  0% {
    -webkit-transform: translate(-50%, -75%) rotate(0deg);
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -75%) rotate(360deg);
    transform: translate(-50%, -75%) rotate(360deg);
  }
} 


.main-loading span {
  display: inline-block;
  animation: dot 1.5s ease-in-out infinite;
}

@keyframes dot {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
  100% {
      transform: translateY(0);
  }
}

.testvideo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}





.intial-loading {
  
  background-color: #333; 

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

}

.intial-loading h1 {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
}

.intial-loading h1 span{
 color: #06b6d4;
}



.privacy-policy {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.privacy-policy h1{
  text-align: center;
  font-size: 3rem;
}

.privacy-policy p{
    font-size: 1.3rem;
    margin-top: 20px;
    padding: 20px;
}


.wrapper a {
  font-size: 1.1rem;
  margin-right: 5px;
}


.nav-bar .logo .dark-logo {
  display: none;
}

.nav-bar.dark-mode .logo .dark-logo {
display: block;
}

.nav-bar.dark-mode .logo .light-logo {
display: none;
}


.disable-idm {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.pay-page-input {
  min-height: 100vh;
  padding: 150px 0;
}


.clever {
  padding: 50px 0;
  background-color: #f6f6f6;
  transition: var(--main-transition);
  position: relative;
}

@media (max-width:767px) {
  .clever {
    padding: 70px 0;
   
  }
  .clever h2 {
    font-size: 1.8rem !important;
  }
}

.clever.dark-mode {
  background-color: #0d0d0d;
  transition: var(--main-transition);
  color: #fff;
}



.clever h2{
 text-align: center;
 -webkit-text-stroke-width: 1px;
 font-size: 2.5rem;
 margin-bottom: 50px;
 transition: var(--main-transition);
}



.clever h2 span{
color: #06b6d4;
}


.carousel-control-prev,
.carousel-control-next {
  width: 100px;
  height: 100%; /* Set the height of the arrow container */
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: #000; 
  background-color: #000; 
  width: 100px;/* Set the background color of the arrow container */

}


.test-video {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.wallet img {
  width: 50px;
  /* margin-right: 8px; */
}


@media (max-width:767px) {
  .wallet img {
    width: 40px;
    
  }
  .wallet a {
      margin: 0 10px;
  }
  
}
