

.coursess {
    /* min-height: 100vh; */
    transition: var(--main-transition);
    padding: 150px 50px;
}

.coursess .card {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    border: none;
    box-shadow: -5px 5px 20px #0006;
    border-radius: 10px;
}

.coursess .card .image{
    background-color: #000;
    border-radius: 10px 10px 0px 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.coursess .card .image img{
    width: 100%;
    height: 100%;
    /* opacity: 0.8; */
    transition: .5s;
    width: 100%;
    /* border-radius: 10px 10px 0 0; */
}

@media (max-width:767px) {
    .coursess .card .image img{

        height: 100%;
      
    }
}

.coursess .card:hover .image img{
            filter: saturate(130%);
    /* opacity: 1; */
   
        transform: scale(1.1);
    /* border-radius: 10px 10px 0 0; */
}

.coursess .card .about{
         text-align:right;
         width: 100%;
         padding: 15px 15px 20px 15px; 
         position: relative;
}

.coursess .card .about .details h3{
 font-size: 1.5rem;
 font-weight: 600;
}

    .coursess .card .about .details{
        margin-top: 45px;
       }


.coursess a.cardbtn-profile {
    width: 100%;
    color: #fff !important;
    text-align: center;
}

.coursess .card .about .details p{
 font-size: 1.1rem;
 color: #6b7280;
 letter-spacing: -0.5px;
}


.coursess .card .price{
    position: absolute;
    top: 0;
    background-color: var(--color-Main-blue);;
    padding: 15px 25px;
    left: 0;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 0 0 10px 0;
    box-shadow: 0 0 10px 2px rgba(6, 182, 212, 0.5);
}
.coursess .card .price .free{
        
}

.coursess .card .price{
    position: absolute;
    top: 0;
    background-color: var(--color-Main-blue);;
    padding: 15px 25px;
    left: 0;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 0 0 10px 0;
    box-shadow: 0 0 10px 2px rgba(6, 182, 212, 0.5);
}

.coursess .card .dates {
    position: relative;
    padding: 15px 0;
    
}

.coursess .card .dates::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: var(--color-Main-background);
}

.coursess .card .dates .date{
    display: flex;
    align-items: center;     
}

.coursess .card .dates .date img {
    width: 20px;
}

.coursess .card .dates .date span {
   font-size: 1.1rem;
   color: #555;
}
.coursess .card .btns {
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: relative;
   padding: 20px 0 0 0;
}
.coursess .card .btns::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: var(--color-Main-background);

}
.coursess .card .btns a{
   text-decoration: none;
   color: #fff;
   padding: 11px 20px;
   border-radius: 6px;
   transition: var(--main-transition);
   border: 2px solid var(--color-Main-blue);;
}
.coursess .card .btns a:first-child{
    background-color: var(--color-Main-blue);;
    color: #fff;
}

.coursess .card .btns a:last-child{
    /* background-color: #fff; */
    color: var(--color-Main-blue);;
}

.coursess .card .btns a:last-child:hover{
   background-color: var(--color-Main-blue);;
   color: #fff;
}


.coursess.dark-mode {
    background-color: var(--color-Main-dark);;
    transition: var(--main-transition);
    color: #fff;
}
.coursess.dark-mode .card{
    background-color: var(--color-secondry-dark);;
    transition: var(--main-transition);
    color: #fff;
    box-shadow: -5px 5px 20px #0006;
}

.coursess.dark-mode .card .about .details p,
.coursess.dark-mode .card .about .dates span{
    color: #9ca3af ;
}



@media (max-width:767px) {
    .coursess {
       
        padding: 150px 10px;
    }
    .coursess .card .about .details h3 {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .coursess .card .price{
        padding: 10px 30px;
    }
    
    
}


.red-bg {
    background-color: rgb(175, 70, 236)  !important;
   box-shadow: 0 0 10px 2px rgba(175, 70, 236 , 0.5) !important;
;
}